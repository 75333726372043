import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  layout: "unauth",
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      freeTier = _useBaseUtils.freeTier;
    return {
      freeTier: freeTier
    };
  }
});